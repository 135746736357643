import { useLanguage } from "@/common/hooks/pages.hooks";
import useSearchProducts from "@/Apollo/hooks/useSearchProducts";
import { SearchProductsByQueryItem } from "@/common/contracts/home.contracts";

const NO_PRODUCTS: SearchProductsByQueryItem[] = [];

export const useHomepageForYouProducts = () => {
  const { language } = useLanguage();

  const { data } = useSearchProducts({
    variables: {
      recommended: true,
      pageSize: 8,
      lang: language,
    },
  });

  const products = data?.searchProducts?.items ?? NO_PRODUCTS;

  return { products };
};
