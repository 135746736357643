import { QueryHookOptions, useQuery } from "@apollo/client";

// Apollo
import {
  GetCollectionsQuery,
  GetCollectionsQueryVariables,
} from "@whoppah/apollo/schema";
import queryGQL from "@whoppah/apollo/query/collections.graphql";

export const useCollections = (
  options?: QueryHookOptions<GetCollectionsQuery, GetCollectionsQueryVariables>
) => {
  return useQuery(queryGQL, options);
};
