import { IconProps } from "@/common/contracts/icon.contracts";

export const Repeat = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.6476 6.35292C16.0159 4.72292 13.7036 3.78292 11.161 4.04292C7.48722 4.41292 4.46414 7.39292 4.05372 11.0629C3.50316 15.9129 7.25699 20.0029 11.9918 20.0029C15.1851 20.0029 17.9279 18.1329 19.2092 15.4429C19.5295 14.7729 19.049 14.0029 18.3083 14.0029C17.9379 14.0029 17.5875 14.2029 17.4274 14.5329C16.2962 16.9629 13.5834 18.5029 10.6204 17.8429C8.39815 17.3529 6.60633 15.5429 6.13584 13.3229C5.29499 9.44292 8.248 6.00292 11.9918 6.00292C13.6535 6.00292 15.135 6.69292 16.2161 7.78292L14.7046 9.29292C14.0739 9.92292 14.5144 11.0029 15.4053 11.0029H18.999C19.5495 11.0029 20 10.5529 20 10.0029V6.41292C20 5.52292 18.9189 5.07292 18.2883 5.70292L17.6476 6.35292Z"
        fill="currentColor"
      />
    </svg>
  );
};
