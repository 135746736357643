import { Heading } from "@/ui/cva/Heading";
import { LinkButton } from "@/ui/next/LinkButton";
import ProductCard from "@/components/product/ProductCard";
import { useHomepageForYouProducts } from "@/components/Homepage/hooks/useHomepageForYouProducts.hooks";
import { FOR_YOU_ROUTE } from "@/common/static/routes.static";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { useTranslation } from "next-i18next";

export const HomepageForYouBlock = () => {
  const { t } = useTranslation();
  const { products } = useHomepageForYouProducts();

  const handleClick = () => {
    /** Trigger `hp_for_you` event. */
    analyticsService.trackHpForYou();
  };

  return (
    <section className="flex flex-col items-center space-y-6">
      <div className="flex flex-col items-center">
        <Heading as="h3" className="text-center">
          {t("homepage:homepage-for-you-title")}
        </Heading>
        <LinkButton
          href={FOR_YOU_ROUTE}
          appearance="minimal"
          onClick={handleClick}
        >
          {t("homepage:homepage-for-you-block-link")}
        </LinkButton>
      </div>
      <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {products.map((product, index) => (
          <ProductCard
            key={product?.id}
            item={product}
            listName="homepage-for-you"
            index={index}
            onClick={handleClick}
          />
        ))}
      </div>
    </section>
  );
};
