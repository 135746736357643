import { Button } from "@material-ui/core";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  && {
    color: #fff;
    display: flex;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    letter-spacing: 0.05em;
    outline: none;
    background-color: #3c7be2;
    &:hover {
      background-color: #2758a9;
    }
    svg,
    img {
      width: 0.65em;
      height: 0.65em;
      margin-right: 4px;
    }
  }
`;

// export const TertiaryButton = ({ title = "", className = '' }) => {
//   return <StyledButton onClick={onClick} className={className}>{title || "Tegenbod"}</StyledButton>;
// };

// export const SecondaryButton = ({ title = "", className = '' }) => {
//   return <StyledButton secondary className={className}><CheckCircle /> {title || "Aannemen"}</StyledButton>;
// };

// export const PrimaryButton = ({ title = "", className = '' }) => {
//   return <StyledButton primary className={className}><Cancel /> {title || "Afwijzen"}</StyledButton>;
// };
