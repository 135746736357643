import useBrands from "@/Apollo/hooks/useBrands";
import { useLanguage } from "@/common/hooks/pages.hooks";
import { useMemo } from "react";
import { BrandsQuery_brands_Brand } from "@/Apollo/schema";

const NO_BRANDS: BrandsQuery_brands_Brand[] = [];

export const useHomepagePopularBrands = () => {
  const { language } = useLanguage();

  const { data } = useBrands({
    variables: {
      filters: {
        superPopular: true,
      },
      lang: language,
    },
  });

  const brands = useMemo(() => {
    return data?.brands.filter(brand => !!brand.listingPage) ?? NO_BRANDS;
  }, [data?.brands]);

  return { brands };
};
