import { useAuth } from "auth";
import clsx from "clsx";
import Link from "@whoppah/next/link";
import { useRouter } from "next/router";
import React from "react";
import useUpdateMe from "../../scenes/auth/hooks/useUpdateMe";

const LANGUAGE_MAP = {
  en: "EN_US",
  nl: "NL_NL",
};

const LangButton = ({ locale, handleChange }) => {
  const router = useRouter();
  const isActive = router.locale === locale;

  const [path, search] = router.asPath.split("?");
  const searchParams = new URLSearchParams(search);
  searchParams.delete("search");
  const searchParamsString = searchParams.toString();

  const renderLink = () => {
    return (
      <a
        className={clsx(
          "w-full text-sm text-[#282F36] focus:outline-none px-2 py-2",
          { "text-primary-600 pointer-events-none": isActive }
        )}
        onClick={() => handleChange(locale)}
      >
        {locale?.toUpperCase()}
      </a>
    );
  };

  return (
    <div className="flex flex-col items-center px-2">
      {isActive ? (
        renderLink()
      ) : (
        <Link
          href={
            searchParamsString ? [path, searchParamsString].join("?") : path
          }
          locale={locale}
          passHref
          prefetch={false}
        >
          {renderLink()}
        </Link>
      )}
      <div
        className={clsx("w-3/4 h-1 rounded-tr rounded-tl", {
          "bg-primary-500": isActive,
        })}
      />
    </div>
  );
};

export const LangSwitcher = () => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();
  const { locales } = router;

  const [updateMe] = useUpdateMe();

  const updateUserLanguage = async locale => {
    if (!isLoggedIn) return;
    await updateMe({ variables: { locale } });
  };

  const handleChange = async locale => {
    await updateUserLanguage(LANGUAGE_MAP[locale]);
  };

  return (
    <div className="flex">
      {locales
        .sort((a, b) => b.localeCompare(a))
        .map(locale => (
          <LangButton
            key={locale}
            locale={locale}
            handleChange={handleChange}
          />
        ))}
    </div>
  );
};
