import { IconProps } from "@/common/contracts/icon.contracts";

export const VerifiedOutline = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3712_9928)">
        <path
          d="M23 12.0178L20.6 9.21782L20.9 5.51782L17.3 4.71782L15.4 1.51782L12 3.01782L8.6 1.51782L6.7 4.71782L3.1 5.51782L3.4 9.21782L1 12.0178L3.4 14.8178L3.1 18.5178L6.7 19.3178L8.6 22.5178L12 21.0178L15.4 22.5178L17.3 19.3178L20.9 18.5178L20.6 14.8178L23 12.0178ZM18.7 16.9178L16 17.5178L14.6 19.9178L12 18.8178L9.4 19.9178L8 17.5178L5.3 16.9178L5.5 14.1178L3.7 12.0178L5.5 9.91782L5.3 7.11782L8 6.51782L9.4 4.11782L12 5.21782L14.6 4.11782L16 6.51782L18.7 7.11782L18.5 9.91782L20.3 12.0178L18.5 14.1178L18.7 16.9178ZM16.6 7.61782L18 9.01782L10 17.0178L6 13.0178L7.4 11.6178L10 14.2178L16.6 7.61782Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3712_9928">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.0178223)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
