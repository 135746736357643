import React from "react";
import { useTranslation } from "next-i18next";

// Components
import Link from "@whoppah/next/link";
import Image from "@/components/common/Image";

// Contracts
import { PageProductBlock } from "@/common/contracts/page.contracts";

// Utils
import { cn } from "@/common/utils/css.utils";
import { Flavor } from "@/ui/typography/Flavor";

type CollectionCardProps = {
  collection: PageProductBlock;
  minimal?: boolean;
  className?: string;
  onClick?: () => void;
};

export const CollectionCard = ({
  collection,
  minimal,
  className,
  onClick,
}: CollectionCardProps) => {
  const { t } = useTranslation("collections");

  const showDescription = !minimal && collection.description;
  const showLink = !minimal && collection.listingPage;

  const renderContent = () => {
    return (
      <>
        <div
          className={cn("flex flex-col", minimal ? "space-y-2.5" : "space-y-4")}
        >
          <Image
            alt={collection.title}
            src={collection.image}
            width={900}
            height={600}
          />
          {minimal ? (
            <Flavor size="medium" className="text-center">
              {collection.title}
            </Flavor>
          ) : (
            <h4 className="text-2xl font-semibold">{collection.title}</h4>
          )}
          {showDescription && <p>{collection.description}</p>}
        </div>

        {showLink && (
          <div className="mt-6">
            <span className="font-poppins font-semibold text-primary-500">
              {t("collection-read-more-link")}
            </span>
          </div>
        )}
      </>
    );
  };

  const wrapperProps = {
    className: cn("flex flex-col justify-between bg-white", className),
  };

  return collection.listingPage ? (
    <Link href={`/${collection.listingPage.url}`} passHref prefetch={false}>
      <a {...wrapperProps} onClick={onClick}>
        {renderContent()}
      </a>
    </Link>
  ) : (
    <div {...wrapperProps}>{renderContent()}</div>
  );
};
