import { useEffect } from "react";

import { showModal } from "@whoppah/ui/Modal2";
import MissingPaymentInfoModal, {
  MISSING_PAYMENT_INFO_STORAGE_KEY,
} from "@whoppah/components/Homepage/MissingPaymentInfoModal";
import useMerchant from "./useMerchant";

const useShowMissingPaymentInfoModal = () => {
  const { isPaymentInfoMissing, hasAds } = useMerchant();

  useEffect(() => {
    const alreadyDismissed = window.sessionStorage.getItem(
      MISSING_PAYMENT_INFO_STORAGE_KEY
    );

    // Show only on Homepage.
    if (hasAds && isPaymentInfoMissing && !alreadyDismissed) {
      showModal(MissingPaymentInfoModal);
    }
  }, [hasAds, isPaymentInfoMissing]);
};

export default useShowMissingPaymentInfoModal;
