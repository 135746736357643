import useHomepageCategories from "@/Apollo/hooks/useHomepageCategories";
import { useLanguage } from "@/common/hooks/pages.hooks";
import { HomepageCategoriesQuery_categories_Category as HomepageLinkGroup } from "@/Apollo/schema";

const NO_CATEGORIES: HomepageLinkGroup[] = [];

export const useHomepageLinkCloud = () => {
  const { language } = useLanguage();

  const { data } = useHomepageCategories({
    variables: {
      lang: language,
    },
  });

  const links = data?.categories ?? NO_CATEGORIES;

  return { links };
};
