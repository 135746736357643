import { QueryHookOptions, useQuery } from "@apollo/client";
import searchProductsGql from "@whoppah/apollo/query/searchProducts.graphql";
import {
  SearchProductsQuery,
  SearchProductsQueryVariables,
} from "@whoppah/apollo/schema";

const useSearchProducts = (
  options?: QueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>
) => {
  return useQuery(searchProductsGql, options);
};

export default useSearchProducts;
