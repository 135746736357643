import React, { useCallback, useEffect } from "react";
import Modal, { handleModalClose, showModal } from "@/ui/Modal2";
import useLazyAdsAboutToExpire from "@whoppah/apollo/hooks/useLazyAdsAboutToExpire";
import useMe from "../../../scenes/auth/hooks/useMe";
import Image from "../../common/Image";
import SparkIcon from "@/assets/icons/24/Spark";
import Button from "@/ui/Button2";
import Link from "@whoppah/next/link";
import { useTranslation } from "next-i18next";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import MarkdownToJSX from "markdown-to-jsx";
import PropTypes from "prop-types";

const AD_ABOUT_TO_EXPIRE_MESSAGE_TIMESTAMP_KEY =
  "ad_about_to_expire_message_timestamp";

const FIVE_HOURS_IN_MILLISECONDS = 18000000;

const LinkComponent = ({ query = "", onClick }) => {
  const { t } = useTranslation();

  const queryParams = query ? `?query=${query}` : "";

  return (
    <Link href={`/account/ads${queryParams}`} prefetch={false}>
      <a className="text-primary-500 focus:outline-none" onClick={onClick}>
        {t("common:your-ads-title")}
      </a>
    </Link>
  );
};

/** To satisfy SonarLint. */
LinkComponent.propTypes = {
  query: PropTypes.string,
  onClick: PropTypes.func,
};

/**
 * @typedef {import("@whoppah/apollo/schema").Product} Product
 */

/**
 * Ad is about to expire modal.
 *
 * @param {Product} ad
 *
 * @return {JSX.Element}
 * @constructor
 */
const AdIsAboutToExpireModal = NiceModal.create(({ ad }) => {
  const { t } = useTranslation();

  const modal = useModal();
  const name = ad.title;
  const queryParams = name ? `?query=${name}` : "";

  return (
    <Modal open={modal.visible} onClose={handleModalClose(modal)}>
      <div className="-mt-[48px] flex max-w-[640px] flex-col items-center gap-[24px] md:px-[64px]">
        <div className="flex flex-col items-center">
          <SparkIcon className="h-[50px] w-[50px]" />
          <div className="h-[192px] w-[192px] rounded-[4px] p-[16px] shadow-md">
            <div className="relative h-full w-full">
              {ad.image?.url ? (
                <Image
                  src={ad.image.url}
                  alt="Photo"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              ) : (
                <div className="absolute bottom-0 left-0 right-0 top-0 bg-[#3c7be2]" />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-[16px]">
          <span className="font-poppins text-[16px] font-semibold md:text-[24px]">
            {t("common:ad-about-to-expire-title")}
          </span>
          <span className="text-center font-roboto text-[14px]">
            <MarkdownToJSX>
              {t("common:ad-about-to-expire", {
                name: name,
                interpolation: { escapeValue: false },
              })}
            </MarkdownToJSX>{" "}
            <LinkComponent query={name} onClick={handleModalClose(modal)} />.
          </span>
        </div>
        <div className="mt-[16px] grid w-full gap-[16px] md:grid-cols-2">
          <Button
            label={t("common:common-close")}
            outlined={true}
            color="secondary"
            onClick={handleModalClose(modal)}
            className="order-2 md:order-1"
          />
          <Button
            label={t("common:ad-reactivate")}
            href={`/account/ads${queryParams}`}
            color="secondary"
            onClick={handleModalClose(modal)}
            className="order-1 md:order-2"
          />
        </div>
      </div>
    </Modal>
  );
});

const useAdIsAboutToExpire = () => {
  const { data: meData } = useMe();

  // We use lazy query because we want full control of when we are fetching data.
  const [getAds] = useLazyAdsAboutToExpire();

  const getAdsFunc = useCallback(
    async merchantId => {
      const now = Date.now();

      /**
       * Update the timestamp, even if we will have expired ads or not.
       * This will postpone the next fetch for 5 hours.
       */
      window.localStorage.setItem(
        AD_ABOUT_TO_EXPIRE_MESSAGE_TIMESTAMP_KEY,
        `${now + FIVE_HOURS_IN_MILLISECONDS}`
      );

      const ads = await getAds({
        variables: { merchantId },
      });

      if (ads?.data?.products?.items?.length > 0) {
        showModal(AdIsAboutToExpireModal, { ad: ads.data.products.items[0] });
      }
    },
    [getAds]
  );

  useEffect(() => {
    const now = Date.now();

    const timestamp = parseInt(
      window.localStorage.getItem(AD_ABOUT_TO_EXPIRE_MESSAGE_TIMESTAMP_KEY) ??
        "0"
    );

    const diff = timestamp - now;

    if (diff <= 0) {
      // Fetch ads that are about expire, if any.
      const merchantId = meData?.me?.merchants?.[0]?.id;
      if (merchantId) {
        getAdsFunc(merchantId);
      }
    }
  }, [getAds, getAdsFunc, meData]);
};

export default useAdIsAboutToExpire;
