import { IconProps } from "@/common/contracts/icon.contracts";

const Filled = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.3492 20.4734C12.5895 21.1772 11.4199 21.1772 10.6602 20.4632L10.5503 20.3612C5.3023 15.5162 1.87362 12.3439 2.00357 8.38631C2.06355 6.6523 2.93321 4.98968 4.34267 4.01048C6.98166 2.17446 10.2404 3.03127 11.9997 5.13249C13.759 3.03127 17.0178 2.16426 19.6568 4.01048C21.0662 4.98968 21.9359 6.6523 21.9959 8.38631C22.1358 12.3439 18.6972 15.5162 13.4492 20.3816L13.3492 20.4734Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Filled;
