import { useAuth } from "@/auth";
import { Heading } from "@/ui/cva/Heading";
import { LinkButton } from "@/ui/next/LinkButton";
import ProductCard from "@/components/product/ProductCard";
import { useHomepageFavoriteProducts } from "@/components/Homepage/hooks/useHomepageFavoriteProducts.hooks";
import { ACCOUNT_FAVORITES_ROUTE } from "@/common/static/routes.static";
import { useTranslation } from "next-i18next";
import { analyticsService } from "@/common/services/analytics/analytics.service";

export const HomepageFavoritesBlock = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { favorites } = useHomepageFavoriteProducts();

  const handleSeeAllClick = () => {
    /** Trigger `hp_favourites` event. */
    analyticsService.trackHpFavorites();
  };

  if (!isLoggedIn || !favorites.length) {
    return null;
  }

  return (
    <section className="flex flex-col items-center space-y-6">
      <div className="flex flex-col items-center">
        <Heading as="h3" className="text-center">
          {t("home:homepage-user-favorites-title")}
        </Heading>
        <LinkButton
          href={ACCOUNT_FAVORITES_ROUTE}
          appearance="minimal"
          onClick={handleSeeAllClick}
        >
          {t("home:homepage-user-favorites-link")}
        </LinkButton>
      </div>
      <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {favorites.map((product, index) => (
          <ProductCard
            key={product?.id}
            item={product}
            listName="home-favorites"
            index={index}
          />
        ))}
      </div>
    </section>
  );
};
