import clsx from "clsx";
import React from "react";
import Spinner from "./spinner";
import Link from "@whoppah/next/link";

const BUTTON_COLOR_CLASSES = {
  base: (size, outlined, loading) => {
    return clsx(
      size === "normal" ? "px-[24px]" : "px-[16px]",
      size === "normal" ? "py-[12px]" : "py-[4px]",
      "flex",
      "items-center",
      "justify-center",
      "border",
      "rounded-[6px]",
      "font-title",
      "font-semibold",
      "text-[16px]",
      "ring-offset-0",
      "focus:outline-none",
      "focus:ring-2",
      outlined && "bg-opacity-0",
      loading && "cursor-not-allowed"
    );
  },
  primary: (outlined, loading) => {
    if (outlined)
      return clsx(
        "border-primary-500",
        "bg-primary-500",
        "text-primary-500",
        "ring-primary-200",
        !loading && "hover:bg-opacity-5 active:bg-opacity-10"
      );

    return clsx(
      "border-primary-500",
      "bg-primary-500",
      "text-white",
      "ring-primary-200",
      !loading && "hover:bg-primary-600 active:bg-primary-700"
    );
  },
  secondary: (outlined, loading) => {
    if (outlined)
      return clsx(
        "border-secondary-500",
        "bg-secondary-500",
        "text-secondary-500",
        "ring-secondary-200",
        !loading && "hover:bg-opacity-5 active:bg-opacity-10"
      );

    return clsx(
      "border-secondary-500",
      "bg-secondary-500",
      "text-white",
      "ring-secondary-200",
      !loading && "hover:bg-secondary-600 active:bg-secondary-700"
    );
  },
  tertiary: (outlined, loading) => {
    if (outlined)
      return clsx(
        "border-curious-500",
        "bg-curious-500",
        "text-curious-500",
        "ring-curious-200",
        !loading && "hover:bg-opacity-5 active:bg-opacity-10"
      );

    return clsx(
      "border-curious-500",
      "bg-curious-500",
      "text-white",
      "ring-curious-200",
      !loading && "hover:bg-curious-600 active:bg-curious-700"
    );
  },
  disabled: outlined => {
    return clsx(
      "border-gray-400",
      "border-opacity-60",
      "bg-gray-300",
      "text-gray-400",
      "cursor-not-allowed",
      outlined && "bg-opacity-0 text-opacity-60"
    );
  },
};

/**
 * @param {object} WrapperProps
 * @property {string} [href] - Wrapper href.
 * @property {"button" | "submit"} [type="button"] - Wrapper type if this is a button.
 * @property {React.ReactNode} [children] - Wrapper children. Will ignore both "icon" and "label" props if used.
 * @property {string} [className] - Additional classes to pass onto Wrapper.
 * @property {...any} [props] - Rest of the props.
 */

/**
 * Wrapper
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<WrapperProps> & React.RefAttributes<unknown>>}
 */
const Wrapper = React.forwardRef(
  (
    {
      href = undefined,
      type = "button",
      children,
      className,
      target = "_self",
      ...props
    },
    ref
  ) => {
    const { style, onClick, ...rest } = props;

    return href ? (
      <Link href={href} {...rest}>
        <a
          ref={ref}
          style={style}
          className={className}
          target={target}
          onClick={onClick}
        >
          {children}
        </a>
      </Link>
    ) : (
      <button
        ref={ref}
        style={style}
        type={type}
        className={className}
        {...props}
      >
        {children}
      </button>
    );
  }
);

/**
 * @param {object} Props
 * @property {"button" | "submit"} [type="button"] - Button type.
 * @property {"primary" | "secondary" | "tertiary" | ((outlined: boolean, loading: boolean) => string)=} [Props.color="primary"] - Button color.
 * @property {"normal" | "small"} [size="normal"] - Button size.
 * @property {boolean} [outlined=false] - Whether the button is rendered as outlined variant.
 * @property {boolean} [loading=false] - Whether the button is loading (busy).
 * @property {boolean} [disabled=false] - Whether the button is disabled.
 * @property {"horizontal" | "vertical"} [orientation="horizontal"] - Button orientation.
 * @property {JSX.Element} [iconLeft] - Left Button icon .
 * @property {JSX.Element} [iconRight] - Right Button icon.
 * @property {string} [label] - Button label.
 * @property {string} [href] - Button href. If href is passed, button is rendered as anchor.
 * @property {string} [className] - Additional classes to pass onto button.
 * @property {React.ReactNode} [children] - Button children. Will ignore both "icon" and "label" props if used.
 * @property {...any} [props] - Rest of the props.
 */

/**
 * Simple button.
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<Props> & React.RefAttributes<HTMLButtonElement>>}
 */
const Button2 = React.forwardRef(
  (
    {
      type = "button",
      color = "primary",
      size = "normal",
      outlined = false,
      loading = false,
      disabled = false,
      orientation = "horizontal",
      iconLeft,
      iconRight,
      label,
      href = undefined,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const getColorClasses = (color, outlined, loading) => {
      return typeof color === "function"
        ? color(outlined, loading)
        : BUTTON_COLOR_CLASSES[color](outlined, loading);
    };

    return (
      <Wrapper
        ref={ref}
        type={type}
        href={href}
        className={clsx(
          BUTTON_COLOR_CLASSES.base(size, outlined, loading),
          disabled
            ? BUTTON_COLOR_CLASSES.disabled(outlined)
            : getColorClasses(color, outlined, loading),
          orientation === "horizontal" ? "flex-row" : "flex-col",
          className
        )}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <Spinner className="w-[24px] h-[24px]" />
        ) : (
          children || (
            <span className="flex gap-2 items-center">
              {iconLeft && (
                <span className={clsx(disabled && "text-gray-400")}>
                  {iconLeft}
                </span>
              )}
              {label && <span>{label}</span>}
              {iconRight && (
                <span className={clsx(disabled && "text-gray-400")}>
                  {iconRight}
                </span>
              )}
            </span>
          )
        )}
      </Wrapper>
    );
  }
);

export default Button2;
