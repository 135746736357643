import { IconProps } from "@/common/contracts/icon.contracts";

export const AwardOutline = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5660_12152)">
        <path
          d="M7 21.4026V19.4026H11V16.3026C10.1833 16.1193 9.45417 15.7734 8.8125 15.2651C8.17083 14.7568 7.7 14.1193 7.4 13.3526C6.15 13.2026 5.10417 12.6568 4.2625 11.7151C3.42083 10.7734 3 9.66925 3 8.40259V7.40259C3 6.85259 3.19583 6.38175 3.5875 5.99009C3.97917 5.59842 4.45 5.40259 5 5.40259H7V3.40259H17V5.40259H19C19.55 5.40259 20.0208 5.59842 20.4125 5.99009C20.8042 6.38175 21 6.85259 21 7.40259V8.40259C21 9.66925 20.5792 10.7734 19.7375 11.7151C18.8958 12.6568 17.85 13.2026 16.6 13.3526C16.3 14.1193 15.8292 14.7568 15.1875 15.2651C14.5458 15.7734 13.8167 16.1193 13 16.3026V19.4026H17V21.4026H7ZM7 11.2026V7.40259H5V8.40259C5 9.03592 5.18333 9.60675 5.55 10.1151C5.91667 10.6234 6.4 10.9859 7 11.2026ZM12 14.4026C12.8333 14.4026 13.5417 14.1109 14.125 13.5276C14.7083 12.9443 15 12.2359 15 11.4026V5.40259H9V11.4026C9 12.2359 9.29167 12.9443 9.875 13.5276C10.4583 14.1109 11.1667 14.4026 12 14.4026ZM17 11.2026C17.6 10.9859 18.0833 10.6234 18.45 10.1151C18.8167 9.60675 19 9.03592 19 8.40259V7.40259H17V11.2026Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5660_12152">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.402588)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
