import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  FavoriteProductsQuery,
  FavoriteProductsQueryVariables,
} from "@whoppah/apollo/schema";

import favoriteProductsQuery from "@whoppah/apollo/query/favoriteProducts.graphql";

const useMyFavorites = (
  options?: QueryHookOptions<
    FavoriteProductsQuery,
    FavoriteProductsQueryVariables
  >
) => {
  return useQuery(favoriteProductsQuery, options);
};

export default useMyFavorites;
