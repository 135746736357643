import { Heading } from "@/ui/cva/Heading";
import { LinkButton } from "@/ui/next/LinkButton";
import { CollectionCard } from "@/components/Collections/CollectionCard";
import React from "react";
import { useHomepageCollections } from "@/components/Homepage/hooks/useHomepageCollections.hooks";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { COLLECTIONS_ROUTE } from "@/common/static/routes.static";
import { useTranslation } from "next-i18next";

export const HomepageCollectionsBlock = () => {
  const { t } = useTranslation();
  const { collections } = useHomepageCollections();

  const handleClick = (name: string) => () => {
    /** Trigger `hp_collection_navigation` event. */
    analyticsService.trackHpCollectionNavigation(name);
  };

  return (
    <section className="flex flex-col items-center space-y-6">
      <div className="flex flex-col items-center">
        <Heading as="h3" className="text-center">
          {t("homepage:homepage-collections-block-title")}
        </Heading>
        <LinkButton
          href={COLLECTIONS_ROUTE}
          appearance="minimal"
          onClick={handleClick("ALL")}
        >
          {t("homepage:homepage-collections-block-link")}
        </LinkButton>
      </div>
      <div className="grid w-full grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4">
        {collections.map(collection => (
          <CollectionCard
            key={collection.slug}
            collection={collection}
            minimal
            onClick={handleClick(collection.title)}
          />
        ))}
      </div>
    </section>
  );
};
