import { useHomepagePopularBrands } from "@/components/Homepage/hooks/useHomepagePopularBrands.hooks";
import { Heading } from "@/ui/cva/Heading";
import { LinkButton } from "@/ui/next/LinkButton";
import Link from "@whoppah/next/link";
import Image from "@/components/common/Image";
import React from "react";
import { useBrandOverviewLocalizedPath } from "@/common/hooks/brands.hooks";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { useTranslation } from "next-i18next";

export const HomepagePopularBrandsBlock = () => {
  const { t } = useTranslation();
  const { brands } = useHomepagePopularBrands();
  const { path: allBrandsPath } = useBrandOverviewLocalizedPath();

  const handleClick = (name: string) => () => {
    /** Trigger `bp_brand_navigation` event. */
    analyticsService.trackHpBrandNavigation(name);
  };

  return (
    <section className="flex flex-col items-center space-y-6">
      <div className="flex flex-col items-center">
        <Heading as="h3">{t("homepage:homepage-brands-block-title")}</Heading>
        <LinkButton
          href={allBrandsPath}
          appearance="minimal"
          onClick={handleClick("ALL")}
        >
          {t("homepage:homepage-brands-block-link")}
        </LinkButton>
      </div>
      <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-4">
        {brands.map(brand => (
          <Link key={brand.id} href={`/${brand.listingPage?.url}`} passHref>
            <a
              className="h-24 rounded-[0.25rem] border border-grey-300"
              onClick={handleClick(brand.title)}
            >
              <div className="relative h-full">
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={brand.image}
                  alt={`${brand.title} logo`}
                />
              </div>
            </a>
          </Link>
        ))}
      </div>
    </section>
  );
};
