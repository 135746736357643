import { Homepage as Page } from "@whoppah/components/Homepage/Homepage";
import getTranslations from "../lib/getTranslations";
import Header from "@whoppah/components/Header/Header";
import { Footer } from "@whoppah/components/Footer/Footer";
import { apolloClientToProps, initializeApollo } from "../lib/apollo";
import homepageCategoriesQuery from "@whoppah/apollo/query/homepageCategories.graphql";
import LanguageUtils from "@whoppah/utils/LanguageUtils";
import { composeProps } from "next-compose-props";

const Homepage = () => {
  return <Page />;
};

Homepage.getLayout = page => {
  return (
    <div>
      <Header />
      {page}
      <Footer />
    </div>
  );
};

const getHomePageCategoriesFromBackend = async ({ locale }) => {
  const client = initializeApollo();

  await client.query({
    query: homepageCategoriesQuery,
    variables: {
      lang: LanguageUtils.getLanguage(locale),
    },
  });

  return apolloClientToProps(client);
};

const homePageTranslations = async ({ locale }) => {
  return {
    props: await getTranslations(locale, ["home", "homepage", "category"]),
  };
};

export const getStaticProps = composeProps(
  getHomePageCategoriesFromBackend,
  homePageTranslations
);

export default Homepage;
