import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { useState } from "react";
import { Security as SecurityIcon } from "@whoppah/icons/24/Security";
import Button from "@whoppah/ui/Button2";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Modal from "@whoppah/ui/Modal2";

export const MISSING_PAYMENT_INFO_STORAGE_KEY =
  "missing-merchant-data-popup-dismissed";

const MissingPaymentInfoModal = NiceModal.create(() => {
  const { t } = useTranslation();
  const router = useRouter();

  // NOTE - This doesn't work properly. It adds all namespaces in a blocking way which is worse
  // than adding only account-payment namespace for all pages. So it is now added to
  // getTranslations function as common namespace

  // if (!i18n.hasResourceBundle(router.locale, 'account-payment')) {
  //   i18n.addResourceBundle(
  //     router.locale,
  //     'account-payment',
  //     require(`/public/locales/${router.locale}/account-payment.json`)
  //   );
  // }

  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    modal.resolve();
    modal.hide();
  };

  const handleClick = async () => {
    setIsLoading(true);

    try {
      window.sessionStorage.setItem(MISSING_PAYMENT_INFO_STORAGE_KEY, "true");
      await router.push("/account/settings#payment-information");
      handleClose();
    } catch (ex) {
      console.log("PaymentsOverlay:handleClick", ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={modal.visible}
      icon={<SecurityIcon className="text-secondary-500" />}
      title={t("account-payment:my-payments-incomplete-info-title")}
      onClose={handleClose}
    >
      <div className="flex flex-col items-center gap-[16px]">
        <span className="max-w-[384px] text-center font-roboto">
          {t("account-payment:my-payments-incomplete-info")}
        </span>
        <Button
          className="w-full max-w-[256px]"
          label={t("account-payment:my-payments-incomplete-button")}
          color="secondary"
          loading={isLoading}
          onClick={handleClick}
        />
      </div>
    </Modal>
  );
});

export default MissingPaymentInfoModal;
