import { Flavor } from "@/ui/typography/Flavor";
import { useTranslation } from "next-i18next";

type USPNoteProps = {
  icon: JSX.Element;
  label: string;
};

export const USPNote = ({ icon, label }: USPNoteProps) => {
  const { t } = useTranslation();

  return (
    <span>
      {icon}
      <Flavor className="text-center align-middle">{t(label)}</Flavor>
    </span>
  );
};
