const Plus = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" fill="currentColor" />
    </svg>
  );
};

export default Plus;
