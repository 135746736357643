import { Heading } from "@/ui/cva/Heading";
import { useHomepageLinkCloud } from "@/components/Homepage/hooks/useHomepageLinkCloud.hooks";
import { HomepageCategoriesQuery_categories_Category as HomepageLinkGroup } from "@/Apollo/schema";
import Link from "@whoppah/next/link";
import { analyticsService } from "@/common/services/analytics/analytics.service";

type LinkColumnProps = {
  group: HomepageLinkGroup;
};

const LinkColumn = ({ group }: LinkColumnProps) => {
  const handleClick = (slug?: string) => () => {
    analyticsService.trackHpCategoryNavigation(slug);
  };

  return (
    <div className="flex flex-col space-y-2.5">
      <Link href={`/${group.route}`}>
        <a className="hover:underline" onClick={handleClick(group.slug_en)}>
          <Heading as="h6">{group.title}</Heading>
        </a>
      </Link>
      <ul>
        {group.children?.map(link => (
          <li key={link?.slug_en}>
            <Link href={`/${link?.route}`} passHref>
              <a
                className="hover:underline"
                onClick={handleClick(link?.slug_en)}
              >
                <span className="font-body text-sm">{link?.title}</span>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const HomepageLinkCloudBlock = () => {
  const { links } = useHomepageLinkCloud();

  return (
    <section className="grid w-full grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 lg:grid-cols-4">
      {links.map(group => (
        <LinkColumn key={group.slug_en} group={group} />
      ))}
    </section>
  );
};
