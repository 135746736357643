import { IconProps } from "@/common/contracts/icon.contracts";

export const Security = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05778 4.85926L11.28 2.15026C11.7333 1.94991 12.2667 1.94991 12.72 2.15026L18.9422 4.85926C19.5822 5.13799 20 5.76516 20 6.4533V10.5473C20 15.3817 16.5867 19.9025 12 21C7.41333 19.9025 4 15.3817 4 10.5473V6.4533C4 5.76516 4.41778 5.13799 5.05778 4.85926ZM7.28898 12.8991L9.5912 15.1552C9.93787 15.4949 10.5068 15.4949 10.8445 15.1552L16.7023 9.41489C17.049 9.07518 17.049 8.52641 16.7023 8.1867C16.3556 7.84698 15.7956 7.84698 15.449 8.1867L10.2223 13.3085L8.54231 11.6709C8.19565 11.3312 7.63565 11.3312 7.28898 11.6709C7.12253 11.8337 7.02899 12.0546 7.02899 12.285C7.02899 12.5154 7.12253 12.7364 7.28898 12.8991Z"
        fill="currentColor"
      />
    </svg>
  );
};
