import Spark from "@/assets/icons/24/Spark";
import { AppDownloadButton } from "@/common/components/AppDownloadButton";
import { ANDROID_LINK, IOS_LINK } from "@/common/static/apps.static";
import Image from "@/components/common/Image";
import QRCodeImage from "@/assets/app-download-qr.png";
import { Heading } from "@/ui/cva/Heading";
import { Body } from "@/ui/typography/Body";
import { useTranslation } from "next-i18next";

const HomepageAppDownloadBlockTitle = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-4 text-center md:text-left">
      <div className="flex flex-col items-center md:items-baseline">
        <Spark className="h-20 w-20 text-white md:h-24 md:w-24" />
        <Heading as="h1" className="text-white">
          {t("common:common-download-app-title")}
        </Heading>
      </div>
      <Body className="text-white">{t("common:common-download-app-text")}</Body>
    </div>
  );
};

const HomepageAppDownloadBlockLinks = () => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-x-6 md:grid-cols-[auto_1fr]">
      <div className="relative hidden h-36 w-36 md:block">
        <Image src={QRCodeImage} layout="fill" alt="QR code" />
      </div>
      <div className="flex flex-col">
        <Heading as="h3" className="hidden flex-1 text-white md:block">
          {t("common:common-download-app-qr")}
        </Heading>
        <div className="flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0">
          <AppDownloadButton
            href={IOS_LINK}
            device="ios"
            className="md:h-14 md:w-44"
          />
          <AppDownloadButton
            href={ANDROID_LINK}
            device="android"
            className="md:h-14 md:w-44"
          />
        </div>
      </div>
    </div>
  );
};

export const HomepageAppDownloadBlock = () => {
  return (
    <section className="grid overflow-hidden rounded-[0.375rem] bg-brand-800 md:grid-cols-[6fr_4fr]">
      {/* Left side */}
      <div className="flex flex-col items-center space-y-10 px-8 py-4 md:items-baseline md:space-y-20 md:px-14 md:py-12">
        <HomepageAppDownloadBlockTitle />
        <HomepageAppDownloadBlockLinks />
      </div>
      {/* Right side */}
      <div className="h-[22rem] bg-[url('./components/Homepage/assets/app-download-block-mobile-bg.png')] bg-bottom bg-no-repeat md:h-auto md:bg-[url('./components/Homepage/assets/app-download-block-bg.png')] md:bg-cover md:bg-left" />
    </section>
  );
};
