import useMyFavorites from "@/Apollo/hooks/useMyFavorites";
import { Ordering } from "@/Apollo/schema";
import { useMemo } from "react";
import { FavoriteItemProduct } from "@/common/contracts/home.contracts";
import { isFavoriteItemProduct } from "@/common/utils/type-guards.utils";
import { useLanguage } from "@/common/hooks/pages.hooks";
import useMe from "@/hooks/useMe";

const NO_FAVORITES: FavoriteItemProduct[] = [];

export const useHomepageFavoriteProducts = () => {
  const { language } = useLanguage();
  const { favoritesCount } = useMe();

  const { data } = useMyFavorites({
    variables: {
      limit: 8,
      lang: language,
      order: Ordering.Rand,
    },
    skip: !favoritesCount,
  });

  const favorites = useMemo(() => {
    return (
      data?.favorites?.items?.reduce<FavoriteItemProduct[]>(
        (favorites, { item }) => {
          if (isFavoriteItemProduct(item)) {
            favorites.push(item);
          }

          return favorites;
        },
        []
      ) ?? NO_FAVORITES
    );
  }, [data?.favorites?.items]);

  return { favorites };
};
