import { useLanguage } from "@/common/hooks/pages.hooks";
import { useCollections } from "@/Apollo/hooks/useCollections";
import { useMemo } from "react";
import { isCollection } from "@/common/utils/type-guards.utils";
import { PageProductBlock } from "@/common/contracts/page.contracts";

const NO_COLLECTIONS: PageProductBlock[] = [];

export const useHomepageCollections = () => {
  const { language } = useLanguage();

  const { data } = useCollections({
    variables: {
      name: "collections-homepage-web",
      lang: language,
    },
  });

  const collections = useMemo(() => {
    return (
      data?.pageByKey?.blocks.reduce<PageProductBlock[]>(
        (collections, block) => {
          if (isCollection(block)) {
            collections.push(block);
          }

          return collections;
        },
        []
      ) ?? NO_COLLECTIONS
    );
  }, [data?.pageByKey?.blocks]);

  return { collections };
};
