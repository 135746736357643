import { QueryHookOptions, useQuery } from "@apollo/client";

import brandsGql from "../query/brands.graphql";
import { BrandsQuery, BrandsQueryVariables } from "../schema";

const useBrands = (
  options?: QueryHookOptions<BrandsQuery, BrandsQueryVariables>
) => {
  return useQuery(brandsGql, options);
};

export default useBrands;
