import { useQuery, QueryHookOptions } from "@apollo/client";
import {
  HomepageCategoriesQuery,
  HomepageCategoriesQueryVariables,
} from "@whoppah/apollo/schema";

import homepageCategoriesQuery from "@whoppah/apollo/query/homepageCategories.graphql";

const useHomepageCategories = (
  options?: QueryHookOptions<
    HomepageCategoriesQuery,
    HomepageCategoriesQueryVariables
  >
) => {
  return useQuery(homepageCategoriesQuery, { ...options });
};

export default useHomepageCategories;
